<template>
  <b-card
    no-body
    class="system-settings-card"
  >
    <div>
      <sidebar-form
        :visible="showForm"
        :resource-id="resourceId"
        @saved="fetchList()"
        @close="onCloseForm"
      />
      <div class="m-2 d-flex justify-content-between table-actions">
        <div>
          <b-button
            id="create-button"
            variant="primary"
            :class="{
              'icon-disabled': $can('Create', 'Document') === false,
            }"
            @click="$can('Create', 'Document') && create()"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('Create New') }}</span>
          </b-button>
          <b-tooltip target="create-button">
            {{ $can('Create', 'Document') === true ? $t('Create') : $t('You dont have permission to create document') }}
          </b-tooltip>
        </div>
        <div>
          <prozess-search-input
            :value="search"
            :placeholder="$t('Search')"
            @input="handleSearch"
          />
        </div>
      </div>
      <div
        v-if="!customizeLoading"
        class="m-2"
      >
        <b-table
          ref="refTable"
          :busy="loading"
          :items="items"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          no-local-sorting
          :empty-text="$t('No matching records found')"
          class="table-responsive"
          @sort-changed="handleSortChange"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>

          <template #head()="{ label }">
            <span style="white-space: nowrap">{{ $t(label) }}</span>
          </template>

          <template #cell(signing)="{ item }">
            <b-badge
              variant="outline-secondary"
            >
              <span style="font-size: 1.1rem">{{JSON.stringify(item.signingOrder)}}</span>
            </b-badge>
          </template>

          <template #cell(connectedTo)="{ item }">
            <div
              v-if="item.connectedEntitySingleResponse.length"
              style="width: 200px"
            >
              <span class="text-primary">{{ item.connectedEntitySingleResponse[0].name }}</span>
              <span
                v-if="item.connectedEntitySingleResponse.length > 1"
                class="ml-1 text-primary"
              >(+{{ item.connectedEntitySingleResponse.length - 1 }})</span>
            </div>
          </template>

          <!-- START: for customizable fields -->

          <template #cell()="data">
            <router-link
              v-if="isTitle(data)"
              class="text-nowrap"
              :to="{
                name: 'document-view',
                params: {
                  id: data.item.uuidKey,
                  tab: GenericTabs.PREVIEW,
                },
              }"
            >
              {{ findValue(data) }}
            </router-link>
            <span
              v-else
              :id="`tooltip-${data.index}-${data.field.key}`"
              style="max-width: 200px"
              class="d-block"
              :class="{ 'text-primary': data.field.key.includes('mobile'), 'text-truncate': data.field.type === 'LONGTEXT' }"
            >
              {{ findValue(data) }}
            </span>
            <b-tooltip
              v-if="data.field.type === 'LONGTEXT'"
              :target="`tooltip-${data.index}-${data.field.key}`"
              :title="findValue(data)"
              placement="down"
            />
          </template>

          <!-- END: for customizable fields -->

          <!-- Column: Actions -->
          <template #cell(createdTimestamp)="{ item }">
            <prozess-field-wrapper class="tw-flex-1 tw-pt-3">
              <span
                :id="`tooltip-${item.documentId}`"
                style="max-width: 200px"
                class="d-block"
              >
                {{ item.documentId }}
              </span>
              <b-tooltip
                :target="`tooltip-${item.documentId}`"
                placement="down"
              >
                <span class="d-block">{{ $t('Document ID') }} : {{ item.documentId }}</span>
                <span
                  v-if="item.createdByUserName"
                  class="d-block"
                >{{ $t('Created By') }} : {{ item.createdByUserName }}</span>
                <span
                  v-if="item.createdTimestamp"
                  class="d-block"
                >{{ $t('Date Time') }} : {{ $dayjs(item.createdTimestamp).format('YYYY-MM-DD HH:mm') }}</span>
              </b-tooltip>
            </prozess-field-wrapper>
          </template>
          <template #cell(actions)="{ item }">
            <div class="text-nowrap">
              <feather-icon
                :id="`edit-row-${item.uuidKey}-preview-icon`"
                icon="EditIcon"
                size="16"
                class="mx-1 cursor-pointer"
                :class="{
                  'icon-disabled': $can('Update', 'Document') === false,
                }"
                @click="$can('Update', 'Document') ? edit(item.uuidKey) : null"
              />
              <b-tooltip
                placement="left"
                :title="$can('Update', 'Document') === true ? $t('Edit') : $t('You dont have permission to update document')"
                :target="`edit-row-${item.uuidKey}-preview-icon`"
              />
              <feather-icon
                :id="`delete-row-${item.uuidKey}-preview-icon`"
                icon="TrashIcon"
                size="16"
                class="mx-1 cursor-pointer"
                :class="{
                  'icon-disabled': $can('Delete', 'Document') === false,
                }"
                @click="remove(item.uuidKey, item.version)"
              />
              <b-tooltip
                placement="left"
                :title="$can('Delete', 'Document') === true ? $t('Delete') : $t('You dont have permission to remove document')"
                :target="`delete-row-${item.uuidKey}-preview-icon`"
              />
            </div>
          </template>
        </b-table>
      </div>
      <prozess-pagination
        v-if="!loading"
        class="mx-2 mb-2"
        :class="{ 'd-none': !total }"
        :meta="dataMeta"
        :page="currentPage"
        :total="total"
        :size="perPage"
        @change="handleCurrentPage"
      />
    </div>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import { GenericTabs } from '@/constants/app'
import documentService from '@/services/document'
import listMixinFactory from '@/mixins/list'
import SidebarForm from './Form.vue'

export default {
  components: {
    SidebarForm,
  },
  mixins: [
    listMixinFactory({
      routeName: 'documents',
      service: documentService,
      isCustomizable: true,
      listFormat: true,
      tableColumns: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'description', label: 'Description', class: 'disabled' },
        { key: 'connectedTo', label: 'Connected To', class: 'disabled' },
        { key: 'signing', class: 'text-center disabled' },
        { key: 'actions', class: 'text-center disabled' },
      ],
      searchField: 'name',
      sort: {
        name: 'name',
      },
    }),
  ],
  data() {
    return {
      GenericTabs,
    }
  },
  methods: {
    create() {
      this.resourceId = null
      this.showForm = true
    },
    remove(id, version) {
      if (this.$can('Delete', 'Document') === false) return
      this.$swal({
        title: this.$t('Warning'),
        text: this.$t('Are you sure you want to delete?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteResource(id, version)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.document-description {
  width: 200px;
}
</style>
